import React from "react";

function About() {
  const timeSinceMarch2023 = () => {
    const calculateTimeSince = () => {
      const startDate = new Date("2023-03-01"); // March 1, 2023
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const diffTime = currentDate - startDate;

      // Convert milliseconds to days
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      // Calculate years and remaining days
      const years = diffDays / 365; // Use decimal for years
      const wholeYears = Math.floor(years);
      const decimalPart = years - wholeYears;

      // Determine if there are more than half a year
      const displayYears = wholeYears + (decimalPart > 0.5 ? 1 : 0);

      return { displayYears, decimalPart };
    };

    const numberToWords = (num) => {
      const ones = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      if (num < 20) return ones[num];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 > 0 ? " " + ones[num % 10] : "")
        );
      if (num < 1000)
        return (
          ones[Math.floor(num / 100)] +
          " hundred" +
          (num % 100 > 0 ? " and " + numberToWords(num % 100) : "")
        );

      return num.toString(); // Fallback for larger numbers
    };

    const { displayYears, decimalPart } = calculateTimeSince();

    return `${
      decimalPart > 0.5
        ? numberToWords(displayYears - 1)
        : numberToWords(displayYears)
    }${" "} ${decimalPart > 0.5 ? " and a half" : ""} year${
      displayYears !== 1 ? "s" : ""
    } ago`;
  };

  return (
    <div className="section aboutme" id="about">
      {/* <img
        src="./aboutme_bg.png"
        alt="about me background"
        className="aboutme-image"
      /> */}
      {/* <div className="aboutme-image-overlay"></div> */}
      <div className="aboutme-content">
        <h1>Who is Rudy?</h1>
        <div className="aboutme-inline-text">
          <span>Climber, Coach, Student, Content Creator... </span>
          <h2>But what else?</h2>
        </div>
        <p>
          I'm a climber, just like you! I started climbing{" "}
          {timeSinceMarch2023()}, initially with the intention of climbing
          irregularly and just for fun. Fast forward to now, and I’m on the wall
          at every opportunity, inspiring others to do the same. Growing up, I
          enjoyed pop-up walls at camps and events, but I never realized how
          vast the climbing community is or that it was a sport in its own
          right!
          <br />
          <br />
          My love for climbing truly began when I tried out the wall on my
          college campus. Although it’s not the largest wall, the top-rope
          routes were well set, and the community of climbers was both strong
          and positive. I vividly remember starting my first project, a 5.9
          named Lemon Peppa Steppas. The journey of breaking through that
          barrier hooked me on climbing!
          <br />
          <br />
          Teaching has always been a passion of mine, and I’ve had the privilege
          of working with various age groups. Now, I’m excited to blend that
          passion with my favorite activity by helping beginners and
          intermediate climbers get up to speed on everything bouldering. I
          believe climbing is not just about sending new heights but also about
          fostering a supportive community. After all, it was the encouragement
          from the people around me that pushed me to break through my limits
          and continue climbing.
          <br />
          <br />
          By subscribing to rudykirkclimbs, you’re joining a community dedicated
          to uplifting one another and conquering greater heights together.
          <br />
          <br />
          Over the next few years, I hope to provide immense value across my
          social media platforms while cultivating a healthy community of
          climbers. I’m thrilled to announce upcoming courses that will detail
          the ins and outs of bouldering, enabling anyone to embark on their
          climbing journey or overcome their plateaus and become the best
          climber they can be. I’m also eager to share weekly climbing content
          through my newsletter, including tips, tricks, news, events, and so
          much more!
          <br />
          <br />
          If you're interested in climbing or have any questions, don’t hesitate
          to reach out and join my newsletter! Let’s inspire each other to climb
          higher!
          <br />
          <br />
          Fun fact: My favorite climbing memory so far is sending my first V6
          project.
        </p>
      </div>
    </div>
  );
}
export default About;
